import React, { Component } from "react"
import { navigate } from "gatsby"
import get from "lodash/get"
import BackgroundImage from "gatsby-background-image"
import Modal from "../components/modal"
import "../scss/components/hero.scss"

let key = ""

class Hero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      emailError: '',
      passError: '',
      loginDisabled: false,
      showModal: false,
      modalContent: { youtubeId: "", title: "" },
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModalClick = this.handleModalClick.bind(this)
  }

  handleChange(e) {
    const tar = e.target;
    this.setState({[tar.name]: tar.value});
  }

  handleValidation() {
    let email = this.state.email;
    let password = this.state.password;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let formValid = true;
    if (!email && !password) {
      formValid = false;
      this.setState({emailError: "Please enter a valid email address"});
      this.setState({passError: "Please enter a valid password"});
    } else if (!email) {
      formValid = false;
      this.setState({emailError: "Please enter a valid email address"});
    } else if (!re.test(email)) {
      formValid = false;
      this.setState({emailError: "Please enter a valid email address"});
    } else if (!password) {
      formValid = false;
      this.setState({passError: "Please enter a valid password"});
      this.setState({emailError: ""});
    } else if (password !== key) {
      formValid = false;
      this.setState({passError: "Invalid email and/or password"});
      this.setState({emailError: ""});
    } else {
      this.setState({emailError: ""});
      this.setState({passError: ""});
      return true;
    };
    return false;
  }

  handleModalClick(e, videoId, title) {
    e.preventDefault()
    if (videoId || title) {
      this.setState(state => ({
        showModal: !state.showModal,
        modalContent: { youtubeId: videoId, title: title },
      }))
    } else {
      this.setState(state => ({
        showModal: !state.showModal,
        modalContent: { youtubeId: "", title: "" },
      }))
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    let errors = this.handleValidation();
    if (errors === true) {
      this.setState({ loginDisabled: true })
      let path = "/portal/toolkit"
      navigate(path, {state: { loggedIn: true }})
    };
  }

  render() {
    const hero = get(this, "props.data")
    if (get(this, "props.password")) key = get(this, "props.password").nodes[0].password;

    // not all heroes are created equal. creates flags for the checks if there are background, subtitle, or description elements
    const heroTitleFlag =
      hero !== null &&
      hero.heroTitle !== null &&
      hero.heroTitle !== undefined &&
      hero.heroTitle !== ""
        ? true
        : false
    const heroSubFlag =
      hero !== null &&
      hero.heroSubtitle !== null &&
      hero.heroSubtitle !== undefined &&
      hero.heroSubtitle !== ""
        ? true
        : false
    const heroBackFlag =
      hero !== null &&
      hero.backgroundImage !== null &&
      hero.backgroundImage !== undefined &&
      hero.backgroundImage !== ""
        ? true
        : false
    const heroBackMobFlag =
      hero !== null &&
      hero.backgroundImageMobile !== null &&
      hero.backgroundImageMobile !== undefined &&
      hero.backgroundImageMobile !== ""
        ? true
        : false
    const heroDescFlag =
      hero !== null &&
      hero.heroDescription !== null &&
      hero.heroDescription.heroDescription !== null &&
      hero.heroDescription.heroDescription !== undefined &&
      hero.heroDescription.heroDescription !== ""
        ? true
        : false

    // checks if hero contains modal
    const heroModal = hero !== null && hero.modalLink && hero.modalButtonText ? true : false

    // checks if hero contains login  flag
    const heroLogin = hero !== null && hero.login ? true : false

    // checks if hero contains toolkit  flag
    const heroToolkit = hero !== null && hero.toolkit ? true : false

    // checks hero content for foreground image and if that image is overhanging the content below it
    const heroForFlag =
      hero !== null && hero.foregroundImage !== null && hero.foregroundImage !== undefined
        ? true
        : false
    const heroForOver =
      hero !== null && hero.foregroundOverhang !== null && hero.foregroundOverhang === true
        ? "imgFlow overhang col-12 col-md-6"
        : "imgFlow col-4 col-sm-5 col-md-6"

    // checks hero component definition for any custom classes and applies them to the hero container
    const heroCustClass = get(this, "props.custClass")
    const heroClass =
      heroCustClass !== null &&
      heroCustClass !== "" &&
      heroCustClass !== undefined
        ? "hero " + heroCustClass
        : "hero"

    // variable to hold all the content for if there is a background image or not
    const heroCont = (
      <div className="hero__content container row">
        <div className="hero__text col-8 col-sm-7 col-md-6">
          <div className="flow">
            {heroTitleFlag === true && (
              <h1
                className="hero__title"
                dangerouslySetInnerHTML={{
                __html: (heroSubFlag === true ? hero.heroTitle+"<span>"+hero.heroSubtitle+"</span>" : hero.heroTitle)
              }}></h1>
            )}


              

            {heroDescFlag === true && (
              <p
                className="hero__desc"
                dangerouslySetInnerHTML={{
                  __html: hero.heroDescription.heroDescription,
                }}
              ></p>
            )}

            {heroModal && (
              <button
                onClick={e =>
                  this.handleModalClick(e, hero.modalLink, hero.modalButtonText)
                }
                className="btn"
              >
                {hero.modalButtonText}
              </button>
            )}
            {heroLogin && (
              <form onSubmit={this.handleSubmit}>
                <input type="text" name="email" placeholder="Email Address" value={this.state.email} onChange={this.handleChange}></input>
                <span id="error-email" className={"error__span" + (this.state.emailError.length > 0 ? " active" : "")}>{this.state.emailError}</span>
                <input type="password" name="password" placeholder="Password" value={this.state.password} onChange={this.handleChange}></input>
                <span id="error-password" className={"error__span" + (this.state.passError.length > 0 ? " active" : "")}>{this.state.passError}</span>
                <button type="submit" className="btn" disabled={this.state.loginDisabled}>{this.state.loginDisabled === true ? "Loading..." : "Login"}</button>
              </form>
            )}
          </div>
        </div>
        {heroForFlag === true && (
          <div className={heroForOver}>
            <img
              src={hero.foregroundImage.file.url}
              alt={hero.foregroundImage.description}
            />
          </div>
        )}
      </div>
    )

    return (
      <div className={heroClass+((heroLogin===true || heroToolkit===true) ? " hero__login" : "")}>
        {heroBackFlag === true && heroBackMobFlag === true && (
          <BackgroundImage
            className="hero__container hero__bg"
            tag="div"
            fluid={[hero.backgroundImageMobile.fluid, {...hero.backgroundImage.fluid, media: "(min-width: 768px)"}]}
          >
            {heroCont}
          </BackgroundImage>
        )}
        {heroBackFlag === true && heroBackMobFlag === false && (
          <BackgroundImage
            className="hero__container hero__bg"
            tag="div"
            fluid={hero.backgroundImage.fluid}
          >
            {heroCont}
          </BackgroundImage>
        )}
        {heroBackFlag === false && (
          <div className="hero__container hero__nobg">{heroCont}</div>
        )}

        {/* Modal */}
        <Modal
          onClose={e => this.handleModalClick(e)}
          show={this.state.showModal}
          title={this.state.modalContent.title}
        >
          {this.state.youtubeId !== "" && (
            <div className="iframe__wrapper">
              <iframe
                width="100%"
                height="100%"
                src={
                  "https://www.youtube.com/embed/" +
                  this.state.modalContent.youtubeId + 
                  "?autoplay=1"
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </Modal>
      </div>
    )
  }
}
export default Hero
