import * as React from "react"
import Img from "gatsby-image"
import "../scss/components/card.scss"

class Card extends React.Component {
  render() {
    const data = this.props.data
    const dataExternal = data.externalLink
    let externalLink
    if (data.link && dataExternal === true) {
      externalLink = (
        <a href={data.link} target="_blank" rel="noopener noreferrer">
          Read More
          <Img alt="" className="open-icon" fluid={this.props.fluid.fluid} />
        </a>
      )
    } else if (data.link) {
      externalLink = <a href={data.link}>Read More</a>
    } else {
      externalLink = null
    }
    return (
      <div className="card">
        <div className="card__content flow">
          {/* Card Image */}
          {data.cardImage && (
            <Img
              alt={data.cardImage.description}
              fluid={data.cardImage.fluid}
              className="card__img"
            />
          )}

          {/* Secondary Image */}
          {data.secondaryImage && (
            <Img
              alt={data.secondaryImage.description}
              fluid={data.secondaryImage.fluid}
              className="card__secondary-img"
            />
          )}

          {/* Card Title */}
          {data.cardTitle && <h3 className="card__title">{data.cardTitle}</h3>}

          {/* Card Description */}
          {data.cardDescription && (
            <p className="card__desc">{data.cardDescription.cardDescription}</p>
          )}

          {/* Card Link */}
          {externalLink}
        </div>
      </div>
    )
  }
}

export default Card
