import React from "react";
import '../scss/components/modal.scss'

class Modal extends React.Component {
  onClose = e => {
    this.props.onClose && this.props.onClose(e);
  };
  render() {
    if (!this.props.show) {
      return null;
    }
    return (
      <div className="modal">
        <div className="modal__overlay"></div>
        <div className="modal__content">
          <p className="h4 modal__title" dangerouslySetInnerHTML={{__html: this.props.title}}></p>
          <div>{this.props.children}</div>
          <button className="modal__close" onClick={this.onClose}>x</button>
        </div>
      </div>
    );
  }
}

export default Modal;