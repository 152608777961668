import * as React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/hero"
import Card from "../../components/card"

import "../../scss/pages/smartbox-inputs.scss"

class SmartboxInputPage extends React.Component {
  componentDidMount() {
    // Replacing &reg; elements
    const regElements = document.querySelectorAll(".hero__title, .card__desc"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace('®', "<sup>&reg;</sup>");
    })

    const elText = document.querySelectorAll("main h1 span, .company p, .card__desc"); 
    elText.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace("granular formulation", "granular&nbsp;formulation");
      el.innerHTML = el.innerHTML.replace("seed-attacking pests.", "seed-attacking&nbsp;pests.");
    })
    
    // Addition footer text
    const footerText = document.querySelector(".footer__copyright:last-of-type"); 
    const footerAddition = '<p class="footer__copyright">AZTEC, COUNTER, Force, SmartChoice and Thimet are restricted use pesticides. Important: Always read and follow label instructions. See label for listed pests. Some products may not be registered for sale or use in all states or counties. Please check with your state agency responsible for pesticide registration to ensure registration status. All products are EPA registered.</p>'
    footerText.insertAdjacentHTML('afterend', footerAddition);
  }
  render() {
    // Component Content
    const heroContent = get(this, "props.data.contentfulHero")
    const inputList = get(this, "props.data.contentfulList.listItems")
    const externalIcon = get(this, "props.data.externalIcon")

    return (
      <Layout bodyClass="smartbox-input">
        <Seo title="SmartBox Inputs" description="Address multiple agronomic needs at planting by precisely applying granular inputs with SmartBox+." />

        <section className="padding--none">
          <Hero data={heroContent} custClass="img--overhang" />
        </section>

        <section className="bg--green smartbox-list flow--thick">
          <div className="container-sm bg--white">
            <div className="row flow">
              <h2>SmartBox Inputs Available&nbsp;Now</h2>
              <p id="intro">
                SmartBox+™ equipment enables precise, continuous or prescriptive application of a single
                granular product packaged in SmartBox<sup>&reg;</sup>&nbsp;containers.
              </p>
              {inputList.map(item => {
                return (
                  <div className="col-12 col-sm-6" key={item.id}>
                    <Card data={item} fluid={externalIcon} />
                  </div>
                )
              })}
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default SmartboxInputPage

export const SmartboxInputPageQuery = graphql`
  query SmartboxInputPageQuery {
    contentfulHero(contentful_id: { eq: "7iUIMd1h7z0gtKUjIMSfxW" }) {
      id
      heroTitle
      heroSubtitle
      heroDescription {
        heroDescription
      }
      backgroundImage {
        fluid(maxWidth: 1440, resizingBehavior: PAD, background: "#000", quality: 100) {
          ...GatsbyContentfulFluid
        }
        description
      }
      foregroundImage {
        file {
          url
        }
        description
      }
      foregroundOverhang
    }
    contentfulList(listName: { eq: "Smartbox Inputs List" }) {
      listName
      listItems {
        ... on ContentfulCard {
          id
          cardDescription {
            cardDescription
          }
          cardImage {
            fluid (quality: 100) {
              ...GatsbyContentfulFluid
            }
            description
          }
          secondaryImage {
            fluid (quality: 100) {
              ...GatsbyContentfulFluid
            }
            description
          }
          link
          externalLink
        }
      }
    }
    externalIcon: contentfulAsset(title: { eq: "open-icon" }) {
      id
      description
      title
      fluid (quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }
`
